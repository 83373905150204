















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { AuditItem } from "@auditcloud/shared/lib/schemas";
import { MeasureProcessStepQuestion } from "@/store/modules/measureProcess/types";
import AAuditOverviewQuestion from "@/components/widgets/AAuditOverviewQuestion.vue";

@Component({
  components: {
    AAuditOverviewQuestion,
  },
})
export default class AMeasureProcessStepQuestion extends Vue {
  @Prop({
    type: MeasureProcessStepQuestion,
  })
  readonly doc!: MeasureProcessStepQuestion;
}
